import React from 'react'
import { Link } from 'gatsby'
import Header from '../components/Header/header'
import Layout from '../components/layout'
import Intro from '../components/IndexPage/Intro/intro'
import LeftContent from '../components/IndexPage/leftContent'
import RightContent from '../components/IndexPage/rightContent'
import Footer from '../components/Footer/footer'
import ScrollAnimation from 'react-animate-on-scroll';
import {prefix} from '../components/langPrefix'
import "animate.css/animate.min.css";
const IndexPage = () => (
  <Layout>
    <div id="home">
    <Header/>

    <Intro/>
    <section className="section main">
         <div className="container">
         <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="center-line-box">
            <div className="line"></div>
         </ScrollAnimation>
          <div className="columns">
              <LeftContent/>
              <RightContent/>
          </div>
      </div>
    
   </section>
   <Footer/>
   </div>
  </Layout>
)

export default IndexPage

