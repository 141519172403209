import React from 'react'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import {need_driver_title, need_driver_text, read_more, vehicles, home_bottom_text, home_top_text, image_right1_alt, vehicles_alt, image_right2_alt} from '../strings'
import {prefix, link_vehicles} from '../langPrefix'
import image2 from "../../images/Picture-Professional-drivers-for-your-VIP-vehicle.png";
import image1 from "../../images/Slika-VIP-CARS–Rent-a-Car-luksuzna-vozila-u-Bosni,-Srbiji,-Hrvatskoj-i-Crnoj-Gori.jpg";
import image3 from "../../images/Slika-VIP-CARS-SARAJEVO-iznajmljivanje-luksuznih-vozila-sa-profesionalnim-vozačima.jpg";

class RightContent extends React.Component{
    render(){
        return(
    <div className="column column-right">
   <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="content">
        <p className="content-text">{home_top_text}</p>      
    </div>
   <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="solo-image">
        <img src={image1} alt="VIP CARS SARAJEVO iznajmljuje auta na svim željenim lokacijama u Bosni, Srbiji, Hrvatskoj i Crnoj Gori" title="VIP CARS SARAJEVO iznajmljuje auta na svim željenim lokacijama u Bosni, Srbiji, Hrvatskoj i Crnoj Gori"/>
    </div>
    
    <div className="columns half-image is-margin-0">
        <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column content is-6">
            <h3 className="content-title">{need_driver_title}</h3>
            <p className="content-text">{need_driver_text}
</p>
           {/* <Link to="/" className="read-more">{read_more}  <FontAwesomeIcon icon={['fas', 'arrow-right']} aria-hidden="true" /></Link>*/}
        </div> 
        <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="column image is-6">
            <img src={image2} alt="Picture – Professional drivers for your VIP vehicle" title="Picture – Professional drivers for your VIP vehicle" className="mobile" />
        </div>
    </div>

     <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="image-over">
            <img src={image3} alt="VIP CARS SARAJEVO  iznajmljuje luksuzna vozila (auta) i profesionalne vozače" title="VIP CARS SARAJEVO  iznajmljuje luksuzna vozila (auta) i profesionalne vozače"/>
        <Link to={prefix + link_vehicles} className="link" title="Pogledajte asortiman luksuznih VIP CARS SARAJEVO vozila (auta)"><h4>{vehicles}</h4> <FontAwesomeIcon icon={['fas', 'arrow-right']} aria-hidden="true" /></Link>
    </div>
    <div animateIn='fadeIn' animateOut='fadeOut' offset="0" delay="0" animateOnce="true" className="content gold">
        <p className="content-text">{home_bottom_text}</p>      
    </div>
</div>
)
        }
    }

export default RightContent

