import React from "react";

import Link from 'gatsby-link'
import {logo_alt} from '../strings'
import {prefix} from '../langPrefix'
import logo from "../../images/VIP-CARS–Luxury-Cars-for-Rent.png";
function Logo() {
    return <Link to={prefix + "/"} ><img src={logo} alt="VIP CARS SARAJEVO – luksuzna auta i profesionalni vozači dostupni za iznajmljivanje na svim lokacijama u regiji - BiH, Srbija Hrvatska i Crna Gora" title="VIP CARS SARAJEVO – luksuzna auta i profesionalni vozači dostupni za iznajmljivanje na svim lokacijama u regiji - BiH, Srbija Hrvatska i Crna Gora" /></Link>;
}

export default Logo;