import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import {intro_title, intro_subtitle} from '../../strings'

class Intro extends React.Component{
    render(){
        return(
    <div className="hero is-fullheight intro">
        <div className="overlay"></div>
        <div className="hero-head">
            
        </div>
      
            <div className="hero-body is-padding-0">
                <ScrollAnimation animateIn='zoomIn' animateOut='zoomOut' offset="0" delay="0" animateOnce="true" className="container">
                <h1 className="big-title is-center">{intro_title}</h1>
                <h1 className="subtitle is-center">{intro_subtitle}</h1>
                <div className="vertical-border is-center"></div>
              
            </ScrollAnimation>
        </div>
        <div className="hero-foot is-padding-0">
            <div className="container">
            </div>
        </div>
    </div>
        );
    }
}

export default Intro
