import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import '../styles/index.scss'
import '../styles/_responsive.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight, faTimes,faMap, faPhone, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import {  } from '@fortawesome/free-regular-svg-icons'

library.add(faArrowRight, faFacebookF, faInstagram, faTwitter, faTimes, faMap, faPhone, faEnvelopeOpen)



const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
     
        <Helmet
          title='VIP CARS - Rent a Car | Luksuzna vozila za BiH, Srbiju, Hrvatsku i CG'
          meta={[
            { name: 'description', content: 'VIP CARS SARAJEVO - iznajmljivanje luksuznih vozila (auta) i profesionalnih vozača na svim lokacijama u regiji - BiH, Srbija, Hrvatska i Crna Gora. T:+387 62 520 556.' },
            { name: 'keywords', content: '' },
          ]}
        >
          <html lang="bs" />
        </Helmet>
        <div>
          <div id="loader">
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
          </div>
      
          {children}
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
